@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  @apply box-border font-sans;
}

body {
  overflow-x: hidden;
}

